import { AuthInfo, call_backend, call_backend_post, imageUrl } from "../common/Backend";

export interface Feed {
    id: number;
    created_at: string;
    updated_at: string;
    name: string;
    active: boolean;
    description: string;
    about: string;
}


export function imageUrlForFeed(feed_name?: string): string {
    return (feed_name) ? imageUrl(`feed_image/${feed_name}`) : '';
}

export async function loadFeeds(authInfo: AuthInfo | undefined): Promise<Feed[]> {
    if (!authInfo) {
        return [];
    }
    const response = await call_backend(authInfo, 'secure/user_feeds');
    return response.json();
}


export async function checkWhetherFeedExists(authInfo: AuthInfo, feedName: string): Promise<boolean> {
    try {
        const response = await call_backend(authInfo, `secure/feed_exists/${feedName}`);
        console.log('response', response);
        return response.status === 200; // 200 means the feed exists
    } catch (e) {
        return false;
    }
}

export async function saveFeed(authInfo: AuthInfo, feed: Feed, image?: File): Promise<void> {
    const formData = new FormData();
    formData.append('id', feed.id.toString());
    formData.append('name', feed.name);
    formData.append('active', feed.active.toString());
    formData.append('description', feed.description);
    formData.append('about', feed.about);
    if (image) {
        formData.append('image', image);
    }

    await call_backend_post(authInfo, 'secure/save_feed', formData);
}