import { useEffect, useState } from 'react';

export const useCardWidth = (containerRef: React.RefObject<HTMLElement | null>, cardMarginPx = 4, posts: any[]) => {
    const [cardWidth, setCardWidth] = useState('250px');

    useEffect(() => {
        const calculateCardWidth = () => {
            if (!containerRef.current) return;
            const containerWidth = containerRef.current.offsetWidth;
            if (containerWidth === 0) return;

            const minCardWidth = 250;
            const maxCardWidth = 400;

            // Calculate the maximum number of cards that can fit in the container width
            const maxCards = Math.floor(containerWidth / (minCardWidth + cardMarginPx * 2));

            // Calculate the actual card width
            const cardWidth = Math.min((containerWidth - (cardMarginPx * 2 * maxCards)) / maxCards, maxCardWidth) - 1;

            //console.log('containerWidth', containerWidth, 'cardWidth', cardWidth, 'maxCards', maxCards, 'margin', `${cardMarginPx}px`);
            setCardWidth(`${cardWidth}px`);
        };

        const handleResize = () => {
            setTimeout(calculateCardWidth, 0);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [containerRef, cardMarginPx, posts]);

    return cardWidth;
};