import { Route, Routes } from 'react-router-dom';
import EditPostPage from "../pages/EditPostPage";
import FeedPage from "../pages/FeedPage";
import HomePage from "../pages/HomePage";
import LogoutPage from "../pages/LogoutPage";
import ManageFeedsPage from "../pages/ManageFeedsPage";
import NotFoundPage from '../pages/NotFoundPage';
import SelectFeedPage from "../pages/SelectFeedPage";
import SubscriptionsPage from "../pages/SubscriptionsPage";
import UserHistoryPage from "../pages/UserHistoryPage";
import UserProfilePage from "../pages/UserProfilePage";
import UserSettingsPage from "../pages/UserSettingsPage";
import ViewMd from "../pages/ViewMdPage";
import ViewPost from "../pages/ViewPostPage";
import CreateRedirector from "./CreateRedirector";
import CurrentFeedRedirector from "./CurrentFeedRedirector";

export const router = (
  <Routes>
    <Route path="/" element={<HomePage />} />
    <Route path="/logout" element={<LogoutPage />} />
    <Route path="/user-profile" element={<UserProfilePage />} />
    <Route path="/create" element={<CreateRedirector />} />
    <Route path="/current-feed" element={<CurrentFeedRedirector />} />
    <Route path="/select-feed/:nextPage" element={<SelectFeedPage />} />
    <Route path="/create-post/:selectedFeed" element={<EditPostPage />} />
    <Route path="/edit-post/:selectedFeed/:selectedPostId" element={<EditPostPage />} />
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/history" element={<UserHistoryPage />} />
    <Route path="/subscriptions" element={<SubscriptionsPage />} />
    <Route path="/feed/:feedName" element={<FeedPage />} />
    <Route path="/manage-feeds" element={<ManageFeedsPage />} />
    <Route path="/post/:feed/:postId" element={<ViewPost />} />
    <Route path="/view-md/:url" element={<ViewMd />} />
    <Route path="/search/:searchTerm" element={<HomePage />} />
    <Route path="/search" element={<HomePage />} />
    <Route path="*" element={<NotFoundPage />} />
  </Routes>
);
