import { Avatar, Box, Button, Card, CardActionArea, CardContent, CardMedia, Link, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { zIndex } from '../config/constants';
import { AuthInfo } from '../services/common/Backend';
import { Post } from '../services/loaders/postLoaders';
import { imageUrlForFeed } from '../services/loaders/userFeeds';
import { extractPreview, extractTitle } from '../services/markdownManipulation';
import DateOrTimeAgo from './DateOrTimeAgo';
const DefaultPostImg = '/example_post.png';
interface Props {
    authInfo: AuthInfo | undefined;
    post: Post;
    width: number | string;
    margin: number | string;
}

const PostCard: React.FC<Props> = ({ post, authInfo, width, margin }) => {
    const navigate = useNavigate();
    const isOwner = post.user_email === authInfo?.providerInfo.email;
    const postLinkUrl = `/post/${post.feed_name}/${post.id}`;
    const handleEdit = () => {
        navigate(`/edit-post/${post.feed_name}/${post.id}`);
    }
    return (
        <Card sx={{ width: width, maxHeight: 390, margin: margin, border: 0, position: 'relative' }} >
            {isOwner && (
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ position: 'absolute', top: 2, right: 2, zIndex: zIndex.postCardEditButton }}
                    onClick={handleEdit}
                >
                    Edit
                </Button>
            )}
            <CardActionArea href={postLinkUrl}  >
                <CardMedia
                    component="img"
                    src={DefaultPostImg}
                    alt="Post Image"
                    sx={{ maxHeight: 180 }}
                />
            </CardActionArea>
            <CardContent sx={{ position: 'relative' }}>
                <CardActionArea href={postLinkUrl}  >
                    <Box sx={{ display: 'flex' }}>
                        <Avatar src={imageUrlForFeed(post.feed_name)} sx={{ width: 32, height: 32, mr: 2 }} />
                        <Tooltip title={extractTitle(post.content || '')}>
                            <Typography
                                variant="h6"
                                component="div"
                                sx={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitLineClamp: 2,
                                    WebkitBoxOrient: 'vertical',
                                    width: '100%'
                                }}
                            >
                                {extractTitle(post.content || '')}
                            </Typography>
                        </Tooltip>
                    </Box>
                    <Tooltip title={extractPreview(post.content || '', 500)}>
                        <Typography
                            variant="body2"
                            color="text.subtitle1"
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: 5,
                                WebkitBoxOrient: 'vertical',
                                width: '100%'
                            }}
                        >
                            {extractPreview(post.content || '', 500)}
                        </Typography>
                    </Tooltip>
                </CardActionArea>
                <Typography variant="body2" color="text.secondary">
                    <Link component={RouterLink} to={`/feed/${post.feed_name}`}>
                        {"@"}{post.feed_name}
                    </Link>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {/* 0 likes -  */}
                    <DateOrTimeAgo date={post.updated_at} />
                </Typography>
            </CardContent>
        </Card >
    );
};

export default PostCard;