import { Box, Button, Collapse, Paper, Typography } from '@mui/material';
import React from 'react';
import { zIndex } from '../config/constants';

const default_post_template = `
# Your mandatory post title

Here you can write your post content. You can use **bold**, *italic*, and [links](https://youread.app) in your post.

`

interface PostHelpCollapsibleProps {
    helpOpen: boolean;
    onToggleHelp: () => void;
}

const PostHelpCollapsible: React.FC<PostHelpCollapsibleProps> = ({ helpOpen, onToggleHelp }) => {

    return (
        <>
            {/* Add collapsible explanation */}
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                <Button
                    onClick={() => onToggleHelp()}
                    sx={{ textTransform: 'none' }}
                >
                    {helpOpen ? "▼" : "▶"} How to write a post?
                </Button>
            </Box>

            <Collapse in={helpOpen} sx={{ zIndex: zIndex.postHelpCollapsible }}>
                <Paper elevation={0}>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                        A post is an individual piece of content that you share within a feed. Each post should be related to the type of feed it belongs to. Here are some good practices for writing a post:
                        <ul>
                            <li>Ensure your content is relevant to the feed's theme</li>
                            <li>Keep your posts clear and concise</li>
                            <li>Use engaging titles and headings</li>
                            <li>Incorporate images, videos, or other media to enhance your post</li>
                            <li>Proofread your content before publishing</li>
                        </ul>

                        To write a post, you can use Markdown, a simple formatting syntax. For more details on how to use Markdown, check out our <a href={`${window.location.origin}/view-md/markdown_tutorial.md`} target="_blank" rel="noreferrer">Markdown Tutorial</a>.
                        <br /><br />
                        Posts can be edited at any time. If you save a post without publishing it, it will be saved as a draft. When you publish a post, it will become visible to other users.
                        <Typography variant="body2" sx={{ mt: 2, fontStyle: 'italic' }}>
                            Here is a boilerplate to get you started:
                        </Typography>
                        <pre>
                            <code>
                                {default_post_template}
                            </code>
                        </pre>
                    </Typography>
                </Paper>
            </Collapse>
        </>
    );
};

export default PostHelpCollapsible;