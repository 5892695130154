import React, { RefObject, useCallback, useEffect, useState } from 'react';
import loadingSvg from '../assets/loading.svg';
import { AuthInfo } from '../services/common/Backend';
export interface Loader {
    (authInfo: AuthInfo | undefined, page: number): Promise<any[]>;
}

export interface LoaderResult {
    items: any[];
    isLoadingNextPage: boolean;
    isLastPageEmpty: boolean;
    loadNextPage: () => void;
    currentPage: number;
}
export interface LoaderResults {
    resultsByLoader: { [key: string]: LoaderResult };
    reloadAll: () => void;
}



export const useNextPageLoader = (
    loaderResult: LoaderResult,
    scrollContainerRef: RefObject<HTMLDivElement | null>,
    itemsContainerRef: RefObject<HTMLDivElement | null>
) => {
    const MAX_INITIAL_PAGES = 10;
    const [initialPagesLoaded, setInitialPagesLoaded] = useState(0);

    const loadNextPageIfNotLast = useCallback(() => {
        if (!loaderResult.isLastPageEmpty && !loaderResult.isLoadingNextPage) {
            loaderResult.loadNextPage();
        }
    }, [loaderResult]);

    const handleScroll = useCallback(() => {
        if (scrollContainerRef && scrollContainerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollContainerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 1) {
                loadNextPageIfNotLast();
            }
        }
    }, [loadNextPageIfNotLast, scrollContainerRef]);

    const loadPagesToFillScrollContainer = useCallback(() => {
        if (scrollContainerRef.current && itemsContainerRef.current) {
            if (initialPagesLoaded >= MAX_INITIAL_PAGES) {
                console.log('Max initial pages loaded', initialPagesLoaded);
                console.log("Make sure the scroll container has a fixed height");
                return;
            }
            const containerHeight = scrollContainerRef.current.clientHeight;
            const itemsHeight = itemsContainerRef.current.scrollHeight;
            if (itemsHeight < containerHeight) {
                loadNextPageIfNotLast();
                setInitialPagesLoaded(prev => prev + 1);
            }
        }
    }, [scrollContainerRef, itemsContainerRef, loadNextPageIfNotLast, initialPagesLoaded]);

    useEffect(() => {
        loadPagesToFillScrollContainer();
        window.addEventListener('resize', loadPagesToFillScrollContainer);
        return () => {
            window.removeEventListener('resize', loadPagesToFillScrollContainer);
        };
    }, [loadPagesToFillScrollContainer]);

    return { handleScroll };
};

export function withLoaders<P extends { authInfo?: AuthInfo }>(
    Component: React.ComponentType<P & {
        loaderResults: LoaderResults
    }>,
    loaders: {
        [key: string]: Loader
    }
): React.FC<Omit<P, 'loaderResults'>> {
    return (props: Omit<P, 'loaderResults'>) => {
        const [loading, setLoading] = useState(true);
        const [error, setError] = useState<Error | null>(null);

        const loadNextPage = useCallback((loaderKey: string, currentPage: number) => {
            console.log('loadNextPage', loaderKey, currentPage + 1);
            const nextPage = currentPage + 1;
            // set isLoadingNextPage to true
            setLoaderResults(prevResults => {
                return {
                    ...prevResults,
                    resultsByLoader: {
                        ...prevResults.resultsByLoader,
                        [loaderKey]: {
                            ...prevResults.resultsByLoader[loaderKey],
                            isLoadingNextPage: true,
                        }
                    }
                };
            });
            // call the loader for the next page
            loaders[loaderKey](props.authInfo, nextPage)
                .then(result => {
                    setLoaderResults(prevResults => ({
                        ...prevResults,
                        resultsByLoader: {
                            ...prevResults.resultsByLoader,
                            [loaderKey]: {
                                ...prevResults.resultsByLoader[loaderKey],
                                items: [...prevResults.resultsByLoader[loaderKey].items, ...result],
                                isLoadingNextPage: false,
                                isLastPageEmpty: result.length === 0,
                                currentPage: nextPage,
                                loadNextPage: () => loadNextPage(loaderKey, nextPage),
                            }
                        }
                    }));
                })
                .catch(error => {
                    setError(error);
                    setLoaderResults(prevResults => ({
                        ...prevResults,
                        resultsByLoader: {
                            ...prevResults.resultsByLoader,
                            [loaderKey]: {
                                ...prevResults.resultsByLoader[loaderKey],
                                isLoadingNextPage: false,
                            }
                        }
                    }));
                });
        }, [props.authInfo]);

        const reloadAll = useCallback(() => {
            setLoading(true);
            setError(null);

            // Set isLoadingNextPage to true for all loaders
            setLoaderResults(prevResults => ({
                ...prevResults,
                resultsByLoader: Object.keys(prevResults.resultsByLoader).reduce((acc, key) => {
                    acc[key] = {
                        ...prevResults.resultsByLoader[key],
                        isLoadingNextPage: true,
                    };
                    return acc;
                }, {} as { [key: string]: LoaderResult })
            }));

            const promises = Object.entries(loaders).map(([key, loader]) =>
                loader(props.authInfo, 1).then(result => [key, result])
            );

            Promise.all(promises)
                .then(results => {
                    setLoaderResults(prevResults => {
                        const newLoaderResults = Object.fromEntries(results.map(([key, result]) => {
                            const isLastPageEmpty = result.length === 0;
                            return [key, {
                                ...prevResults.resultsByLoader[key as keyof typeof prevResults.resultsByLoader],
                                items: result,
                                isLoadingNextPage: false,
                                isLastPageEmpty,
                            }];
                        }));
                        return {
                            ...prevResults,
                            resultsByLoader: newLoaderResults
                        };
                    });
                })
                .catch(setError)
                .finally(() => setLoading(false));
        }, [props.authInfo]);

        const [loaderResults, setLoaderResults] = useState<LoaderResults>({
            resultsByLoader: {
                ...Object.keys(loaders).reduce((acc, key) => {
                    acc[key] = {
                        items: [],
                        isLoadingNextPage: false,
                        isLastPageEmpty: false,
                        loadNextPage: () => loadNextPage(key, 1),
                        currentPage: 1
                    };
                    return acc;
                }, {} as { [key: string]: LoaderResult })
            },
            reloadAll
        });

        useEffect(() => {
            reloadAll();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props.authInfo]);

        if (loading) {
            return <div style={{
                position: "relative",
                width: "100%",
                height: "100vh"
            }}>
                <img src={loadingSvg} alt="Loading..." style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)"
                }} />
            </div>;
        }

        if (error) {
            return <div style={{
                position: "relative",
                width: "100%",
                height: "100vh"
            }}>
                Error: {error.message}
            </div>;
        }


        return <Component {...props as P} loaderResults={loaderResults} />;
    };
}