import { AuthInfo, call_backend, call_backend_post } from "../common/Backend";

export interface Post {
    id: number | null;
    publish?: boolean;
    feed_name: string;
    user_email?: string;
    created_at: Date;
    updated_at: Date;
    category: string;
    content: string;
}


export async function checkWhetherFeedExists(authInfo: AuthInfo, feedName: string): Promise<boolean> {
    try {
        const response = await call_backend(authInfo, `secure/feed_exists/${feedName}`);
        return response.status === 200; // 200 means the feed exists
    } catch (e) {
        return false;
    }
}


export async function savePost(authInfo: AuthInfo, post: Post): Promise<number> {
    const response = await call_backend_post(authInfo, 'secure/save_post', post);
    const responseData = await response.json();
    const post_id = parseInt(responseData.post_id, 10);
    return post_id;
}

export async function loadHomePosts(authInfo: AuthInfo | undefined, page?: number): Promise<Post[]> {
    let url = 'public/home';
    if (page) {
        url += `?page=${page}`;
    }
    const response = await call_backend(authInfo, url);
    const responseData = await response.json();
    const posts: Post[] = responseData.map((post: any) => {
        return {
            ...post,
            created_at: new Date(post.created_at),
            updated_at: new Date(post.updated_at),
        } as Post;
    });
    return posts;
}

export async function searchPosts(authInfo: AuthInfo | undefined, searchTerm: string, page?: number): Promise<Post[]> {
    let url = 'public/search/' + encodeURIComponent(searchTerm);
    if (page) {
        url += `?page=${page}`;
    }
    const response = await call_backend(authInfo, url);
    const responseData = await response.json();
    const posts: Post[] = responseData.map((post: any) => {
        return {
            ...post,
            created_at: new Date(post.created_at),
            updated_at: new Date(post.updated_at),
        } as Post;
    });
    return posts;
}

export async function loadFeedPosts(authInfo: AuthInfo | undefined, feedName: string, page: number): Promise<Post[]> {
    let url = 'public/feed_posts/' + feedName;
    if (page) {
        url += `?page=${page}`;
    }
    const response = await call_backend(authInfo, url);
    const responseData = await response.json();
    const posts: Post[] = responseData.map((post: any) => {
        return {
            ...post,
            created_at: new Date(post.created_at),
            updated_at: new Date(post.updated_at),
        } as Post;
    });
    return posts;
}

export async function loadFeedDrafts(authInfo: AuthInfo | undefined, feedName: string, page?: number): Promise<Post[]> {
    let url = 'secure/feed_drafts/' + feedName;
    if (page) {
        url += `?page=${page}`;
    }
    const response = await call_backend(authInfo, url);
    const responseData = await response.json();
    const posts: Post[] = responseData.map((post: any) => {
        return {
            ...post,
            created_at: new Date(post.created_at),
            updated_at: new Date(post.updated_at),
        } as Post;
    });
    return posts;
}

export async function loadRelatedPosts(authInfo: AuthInfo | undefined, postId: string, page?: number): Promise<Post[]> {
    let url = 'public/related_posts/' + postId;
    if (page) {
        url += `?page=${page}`;
    }
    const response = await call_backend(authInfo, url);
    const responseData = await response.json();
    const posts: Post[] = responseData.map((post: any) => {
        return {
            ...post,
            created_at: new Date(post.created_at),
            updated_at: new Date(post.updated_at),
        } as Post;
    });
    return posts;
}

export async function loadPostDetails(authInfo: AuthInfo | undefined, postId: string): Promise<Post> {
    const response = await call_backend(authInfo, 'public/post_details/' + postId);
    const responseData = await response.json();
    const post: Post = {
        ...responseData,
        created_at: new Date(responseData.created_at),
        updated_at: new Date(responseData.updated_at),
    };
    return post;
}

export async function loadDraftOrPost(authInfo: AuthInfo | undefined, postId: string): Promise<Post> {
    const response = await call_backend(authInfo, 'secure/load_draft/' + postId, true);
    const responseData = await response.json();
    const post: Post = {
        ...responseData,
        created_at: new Date(responseData.created_at),
        updated_at: new Date(responseData.updated_at),
    };
    return post;
}

export async function loadUserSubscriptions(authInfo: AuthInfo | undefined, page?: number): Promise<Post[]> {
    let url = 'secure/user_subscriptions';
    if (page) {
        url += `?page=${page}`;
    }
    const response = await call_backend(authInfo, url);
    const responseData = await response.json();
    const posts: Post[] = responseData.map((post: any) => {
        return {
            ...post,
            created_at: new Date(post.created_at),
            updated_at: new Date(post.updated_at),
        } as Post;
    });
    return posts;
}