

import { ArrowDropDown, Check, NotificationAdd, Notifications, NotificationsOff } from '@mui/icons-material';
import { Box, Button, Menu, MenuItem, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { isSubscribed as checkIsSubscribed, subscribe, unsubscribe } from '../services/loaders/subscriptions';

import { signInAction } from '../hocComponents/AuthProvider';
import { AuthInfo } from '../services/common/Backend';

interface SubscribeButtonProps {
    authInfo: AuthInfo | undefined;
    feedName: string | undefined;
}

const SubscribeButton: React.FC<SubscribeButtonProps> = ({ authInfo, feedName }) => {
    const [isSubscribed, setIsSubscribed] = useState<boolean>(false);
    const [isSubscribeMenuOpen, setIsSubscribeMenuOpen] = useState<boolean>(false);
    const handleSubscribeMenuOpen = () => { setIsSubscribeMenuOpen(true); };
    const handleSubscribeMenuClose = () => { setIsSubscribeMenuOpen(false); };
    const reload = () => {
        if (!authInfo || !feedName) {
            setIsSubscribed(false);
            return;
        }
        checkIsSubscribed(authInfo, feedName).then(setIsSubscribed).catch(console.error);
    }
    useEffect(reload, [authInfo, feedName]);

    const handleSubscribe = () => {
        if (!authInfo || !feedName) {
            return;
        }
        subscribe(authInfo, feedName).then(() => reload()).catch(console.error);
        handleSubscribeMenuClose();
    }
    const handleUnsubscribe = () => {
        if (!authInfo || !feedName) {
            return;
        }
        unsubscribe(authInfo, feedName).then(() => reload()).catch(console.error);
        handleSubscribeMenuClose();
    }
    return (
        <Box>
            <Button id="subscribe-button" variant="contained" color="primary" onClick={(!authInfo) || isSubscribed ? handleSubscribeMenuOpen : handleSubscribe}>
                {(!authInfo || !isSubscribed) && <>
                    <NotificationAdd sx={{ mr: 1 }} /> Subscribe
                </>}
                {authInfo && isSubscribed &&
                    <>
                        <Notifications /> <Typography variant="body2" sx={{ ml: 1, mr: 1 }}> Subscribed</Typography> <ArrowDropDown />
                    </>
                }
            </Button>
            <Menu
                anchorEl={isSubscribeMenuOpen ? document.getElementById("subscribe-button") : null}
                open={isSubscribeMenuOpen}
                onClose={handleSubscribeMenuClose}
            >
                {!authInfo && <MenuItem onClick={signInAction}>Sign in to subscribe</MenuItem>}
                {authInfo && isSubscribed && <MenuItem selected onClick={handleSubscribeMenuClose}><Notifications /> Subscribed <Check /></MenuItem>}
                {authInfo && <MenuItem onClick={handleUnsubscribe}><NotificationsOff /> Unsubscribe</MenuItem>}
            </Menu>
        </Box>
    );
};

export default SubscribeButton;