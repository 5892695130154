import theme from "../styles/theme";

export const drawerWidth = 170;
// Declare constants for any zIndex used in the whole app
export const zIndex = {
    appTopBar: theme.zIndex.drawer + 1,
    CategoriesHeader: 1000,
    postCardEditButton: 1,
    postHelpCollapsible: 9999,
    editPostPageBottomPaper: 10,
    viewPostBottomPaper: 10,
    viewPostShareButton: 20,
};