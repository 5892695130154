
const NotFoundPage = () => {
    return (
        <div style={{
            position: "relative",
            width: "100%",
            height: "100vh"
        }}>
            <h1>404 - Page Not Found</h1>
            <p>The page you are looking for does not exist.</p>
        </div>
    );
};

export default NotFoundPage;