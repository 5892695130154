import { AuthInfo, call_backend } from "../common/Backend";


export interface UserDetails {
  id?: string;
  name?: string;
  email?: string;
  birthdate?: string;
  family_name?: string;
  fullname?: string;
}

export async function loadUserDetails(
  authInfo: AuthInfo | undefined,
): Promise<UserDetails> {
  if (!authInfo) {
    return {
      id: undefined,
      name: "Not logged in user",
      email: "Not logged in user",
      birthdate: "Not logged in user",
      family_name: "Not logged in user",
      fullname: "Not logged in user",
    };
  }
  const response = await call_backend(authInfo, 'secure/user_details');
  const data = await response.json();
  return data;
}
