import { Feed, Home, Subscriptions } from "@mui/icons-material";
import {
  Box,
  Divider,
  Drawer,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { drawerWidth } from "../config/constants";

export const sideBarItems = [
  { title: "Home", icon: <Home />, href: "/" },
  { title: "Subscriptions", icon: <Subscriptions />, href: "/subscriptions" },
];
export const sideBarItemsWhenOpenOnly = [
  { title: "Your feed", icon: <Feed />, href: "/current-feed" },
  // { title: "History", icon: <History />, href: "/history" },
];
interface AppSideBarProps {
  open: boolean;
  isMobileMode: boolean;
}


export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export function AppSideBar({ open, isMobileMode }: AppSideBarProps) {
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  const drawerStyle = {
    width: open ? drawerWidth : `calc(${theme.spacing(7)} )`,
    overflowX: 'hidden',
    ...(isSmUp && !open && {
      width: `calc(${theme.spacing(7)} )`,
    }),
  };
  return (
    <>
      <Drawer
        variant={isMobileMode ? "temporary" : "permanent"}
        open={open}
        sx={{
          width: open ? drawerWidth : `calc(${theme.spacing(7)} )`,
          flexShrink: 0,
          whiteSpace: 'nowrap',
          boxSizing: 'border-box',
          '& .MuiDrawer-paper': drawerStyle,
        }}
      >
        <Toolbar />
        <Box>
          <List>
            {sideBarItems.map((item, _index) => (
              <ListItem key={item.title} disablePadding>
                <ListItemButton component={Link} href={item.href}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          {open && (
            <>
              <Divider />
              <List>
                <ListItem key="you" disablePadding>
                  <ListItemButton component={Link} href="/user-profile">
                    <ListItemText primary={"You >"} />
                  </ListItemButton>
                </ListItem>
                {sideBarItemsWhenOpenOnly.map((item, _index) => (
                  <ListItem key={item.title} disablePadding>
                    <ListItemButton component={Link} href={item.href}>
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.title} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </Box>
      </Drawer>
    </>
  );
}
