import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { loadFeeds } from "../services/loaders/userFeeds";

import { withAuth } from '../hocComponents/withAuth';
import { AuthInfo } from '../services/common/Backend';

interface Props {
    authInfo: AuthInfo;
}

const CreateRedirector: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const [error, setError] = useState<string | null>(null);
    const authInfo = props.authInfo;

    useEffect(() => {
        loadFeeds(authInfo).then((feeds) => {
            switch (feeds.length) {
                case 0:
                    navigate("/manage-feeds");
                    break;
                case 1:
                    navigate(`/create-post/${feeds[0].name}`);
                    break;
                default:
                    navigate("/select-feed/create-post");
                    break;
            }
        }).catch((error) => {
            setError(error.message);
            console.error("Failed to load feeds:", error);
        });
    }, [navigate, authInfo]);

    // This component does not render anything
    return error
        ? <Box textAlign="center" >
            There was an error loading the feeds, please try again later.
        </Box>
        : null;
}

export default withAuth(CreateRedirector);