import { Box } from '@mui/material';
import React, { useRef } from 'react';
import PostCardBox from '../components/PostCardBox';
import { withAuth } from '../hocComponents/withAuth';
import { LoaderResults, useNextPageLoader, withLoaders } from '../hocComponents/withLoaders';
import { AuthInfo } from '../services/common/Backend';
import { loadUserSubscriptions } from '../services/loaders/postLoaders';

type Props = {
    authInfo: AuthInfo;
    loaderResults: LoaderResults;
};

const SubscriptionsPage: React.FC<Props> = (props) => {
    const loadedSubscriptions = props.loaderResults.resultsByLoader.subscriptions;
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const itemsContainerRef = useRef<HTMLDivElement>(null);
    const { handleScroll } = useNextPageLoader(loadedSubscriptions, scrollContainerRef, itemsContainerRef);

    return <Box
        padding={2}
        ref={scrollContainerRef}
        style={{ overflowY: 'auto', height: '100%' }}
        onScroll={handleScroll}
    >
        <h2>Posts from your subscriptions</h2>
        {loadedSubscriptions.items.length === 0 && <Box>No posts found</Box>}
        <div ref={itemsContainerRef}>
            <PostCardBox
                posts={loadedSubscriptions.items}
                authInfo={props.authInfo}
            />
        </div>
    </Box>
        ;
};

export default withAuth(withLoaders(SubscriptionsPage, {
    subscriptions: loadUserSubscriptions
}));