

import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { dislikePost, formatNumLikes, hasPostDislike, hasPostLike, likePost, numDislikes, numLikes, undislikePost, unlikePost } from '../services/loaders/likes';

import { ThumbDownAlt, ThumbDownOffAltOutlined, ThumbUpAlt, ThumbUpOffAltOutlined } from '@mui/icons-material';
import { AuthInfo } from '../services/common/Backend';

interface LikeDislikeButtonsProps {
    authInfo: AuthInfo | undefined;
    postId: string | undefined;
}

const LikeDislikeButtons: React.FC<LikeDislikeButtonsProps> = ({ authInfo, postId }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [hasLike, setHasLike] = useState<boolean>(false);
    const [hasDislike, setHasDislike] = useState<boolean>(false);
    const [numberOfLikes, setNumberOfLikes] = useState<number>(0);
    const [numberOfDislikes, setNumberOfDislikes] = useState<number>(0);
    const [numberOfLikesOffset, setNumberOfLikesOffset] = useState<number>(0);
    const [numberOfDislikesOffset, setNumberOfDislikesOffset] = useState<number>(0);
    const reload = () => {
        if (!authInfo || !postId) {
            console.error("Either authInfo or postId are not defined");
            setHasLike(false);
            setHasDislike(false);
            return;
        }

        hasPostLike(authInfo, postId).then((hasPostLike: boolean) => {
            setHasLike(hasPostLike);
            setNumberOfLikesOffset(hasPostLike ? 1 : 0);
            setNumberOfLikes((prev) => prev - (hasPostLike ? 1 : 0));
        }).catch(console.error);
        hasPostDislike(authInfo, postId).then((hasPostDislike: boolean) => {
            setHasDislike(hasPostDislike);
            setNumberOfDislikesOffset(hasPostDislike ? 1 : 0)
            setNumberOfDislikes((prev) => prev - (hasPostDislike ? 1 : 0));
        }).catch(console.error);
        numLikes(authInfo, postId).then((numPostLikes: number) => {
            setNumberOfLikes((prev) => prev + numPostLikes);
        }).catch(console.error);
        numDislikes(authInfo, postId).then((numPostDislikes: number) => {
            setNumberOfDislikes((prev) => prev + numPostDislikes);
        }).catch(console.error);
    }
    useEffect(reload, [authInfo, postId]);

    const likeHandler = () => {
        if (!postId) {
            return;
        }
        setLoading(true);
        const promise = hasLike ? unlikePost(authInfo, postId) : likePost(authInfo, postId);
        const newLikeOffset = hasLike ? 0 : 1;
        const newDislikeOffset = 0;
        const newHasLike = !hasLike;
        const newHasDislike = false;
        promise.then(() => {
            setNumberOfLikesOffset(newLikeOffset);
            setNumberOfDislikesOffset(newDislikeOffset);
            setHasLike(newHasLike);
            setHasDislike(newHasDislike);
        }).catch(console.error).finally(() => setLoading(false));
    };

    const dislikeHandler = () => {
        if (!postId) {
            return;
        }
        setLoading(true);
        const promise = hasDislike ? undislikePost(authInfo, postId) : dislikePost(authInfo, postId);
        const newLikeOffset = 0;
        const newDislikeOffset = hasDislike ? 0 : 1;
        const newHasLike = false;
        const newHasDislike = !hasDislike;
        promise.then(() => {
            setNumberOfLikesOffset(newLikeOffset);
            setNumberOfDislikesOffset(newDislikeOffset);
            setHasLike(newHasLike);
            setHasDislike(newHasDislike);
        }).catch(console.error).finally(() => setLoading(false));
    };
    const likeIcon = hasLike ? <ThumbUpAlt sx={{ mr: 1 }} /> : <ThumbUpOffAltOutlined sx={{ mr: 1 }} />;
    const dislikeIcon = hasDislike ? <ThumbDownAlt sx={{ mr: 1 }} /> : <ThumbDownOffAltOutlined sx={{ mr: 1 }} />;
    const likeButton = <Button disabled={loading} variant='text' onClick={likeHandler}>{likeIcon} {formatNumLikes(numberOfLikes + numberOfLikesOffset)} </Button>;
    const dislikeButton = <Button disabled={loading} variant='text' onClick={dislikeHandler}>{dislikeIcon}  {formatNumLikes(numberOfDislikes + numberOfDislikesOffset)} </Button>;
    return (
        <>
            {likeButton}
            {dislikeButton}
        </>
    );
};

export default LikeDislikeButtons;