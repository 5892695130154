import React from 'react';
import { getTimeAgo } from '../services/getTimeAgo';

interface DateOrTimeAgoProps {
    date?: Date;
}

const DateOrTimeAgo: React.FC<DateOrTimeAgoProps> = ({ date }) => {
    const formatAbsoluteDate = (date?: Date) => {
        return date?.toLocaleString() || "Unknown";
    }

    return (
        <span title={formatAbsoluteDate(date)}>
            {getTimeAgo(date)}
        </span>
    );
};

export default DateOrTimeAgo;