import React from 'react';
import { withAuth } from '../hocComponents/withAuth';
import { AuthInfo } from '../services/common/Backend';

type Props = {
    authInfo: AuthInfo;
};

const UserHistoryPage: React.FC<Props> = (props) => {

    return (
        <div style={{ textAlign: "center" }}>
            UserHistoryPage TODO This page is under construction
        </div>
    );
};

export default withAuth(UserHistoryPage);