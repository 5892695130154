import { AuthInfo, call_backend, call_backend_post } from "../common/Backend";

export function subscribe(authInfo: AuthInfo, feedName: string) {
    return call_backend_post(authInfo, `secure/subscribe/${feedName}`, {}, true);
}
export function unsubscribe(authInfo: AuthInfo, feedName: string) {
    return call_backend_post(authInfo, `secure/unsubscribe/${feedName}`, {}, true);
}

export async function isSubscribed(authInfo: AuthInfo | undefined, feedName: string): Promise<boolean> {
    if (!authInfo) {
        return false;
    }
    const response = await call_backend(authInfo, `secure/is_subscribed/${feedName}`, true);

    const data = await response.json();

    return data.is_subscribed;
}

export function formatSubscribers(numSubscribers: number): string {
    if (numSubscribers === 0) {
        return '0';
    }
    const abbreviations = ['', 'k', 'M', 'B', 'T'];
    const index = Math.floor(Math.log10(numSubscribers) / 3);
    const formattedNum = (numSubscribers / Math.pow(10, index * 3)).toFixed(0);
    return formattedNum + abbreviations[index];
}

export async function numSubscribers(authInfo: AuthInfo | undefined, feedName: string): Promise<string> {
    const response = await call_backend(authInfo, `public/num_subscribers/${feedName}`, false);

    const data = await response.json();

    return formatSubscribers(data.num_subscribers);
}
