import { FormControl, FormHelperText, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useAuthInfoContext } from '../hocComponents/AuthProvider';
import { loadFeeds } from '../services/loaders/userFeeds';

type Props = {
    // Define your component's props here
};

const SelectFeedPage: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const { nextPage } = useParams<{ nextPage: string }>();
    const [feeds, setFeeds] = useState<string[]>([]);
    const [loadError, setLoadError] = useState<boolean>(false);
    const authInfo = useAuthInfoContext();


    useEffect(() => {
        const fetchFeeds = async () => {
            try {
                const loadedFeeds = await loadFeeds(authInfo);
                const feedNames = loadedFeeds.map((feed) => feed.name);
                setFeeds(feedNames);
                setLoadError(false);
            } catch (err) {
                let message = 'Unknown Error'
                if (err instanceof Error) {
                    message = err.message;
                }
                console.error(`Error loading feeds: ${message} \n${err}`);
                setLoadError(true);
            }
        };

        fetchFeeds();
    }, [authInfo]);

    const handleFeedChange = (event: SelectChangeEvent<string>) => {
        navigate(`/${nextPage}/${event.target.value as string}`)
    };

    return (
        <div
            style={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Typography variant="h4">Select a feed</Typography>
            <Typography variant="body1">
                You have many feeds in your account, please select one of your user feeds
                below to {nextPage === 'feed' ? "view it." : "create a post."}
            </Typography>
            <FormControl style={{ marginTop: '20px' }}>
                <Select
                    labelId="feed-select-label"
                    value=''
                    onChange={handleFeedChange}
                    error={loadError}
                >
                    <MenuItem value="">Select a feed</MenuItem>
                    {feeds.map((feed) => (
                        <MenuItem key={feed} value={feed}>
                            {feed}
                        </MenuItem>
                    ))}
                </Select>
                {loadError &&
                    <FormHelperText error={loadError}>Failed to load user feeds, please refresh the page</FormHelperText>
                }
            </FormControl>
        </div>
    );
};

export default SelectFeedPage;