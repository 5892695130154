
import { Share } from '@mui/icons-material';
import { Box, Button, Popper } from '@mui/material';
import React from 'react';
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    GabIcon,
    GabShareButton,
    HatenaIcon,
    HatenaShareButton,
    InstapaperIcon,
    InstapaperShareButton,
    LineIcon,
    LineShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    LivejournalIcon,
    LivejournalShareButton,
    MailruIcon,
    MailruShareButton,
    PocketIcon,
    PocketShareButton,
    RedditIcon,
    RedditShareButton,
    TelegramIcon,
    TelegramShareButton,
    TumblrIcon,
    TumblrShareButton,
    TwitterShareButton,
    ViberIcon,
    ViberShareButton,
    WeiboIcon,
    WeiboShareButton,
    WhatsappIcon,
    WhatsappShareButton,
    WorkplaceIcon,
    WorkplaceShareButton,
    XIcon
} from "react-share";

interface ShareButtonProps {
    popperZIndex?: number;
}

const ShareButton: React.FC<ShareButtonProps> = ({ popperZIndex }) => {
    const shareUrl = window.location.href;
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const canBeOpen = open && Boolean(anchorEl);
    const id = canBeOpen ? 'transition-popper' : undefined;

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen((previousOpen) => !previousOpen);
    };
    const iconSize = 32;

    const buttons = [
        <FacebookShareButton url={shareUrl} key="facebook">
            <FacebookIcon size={iconSize} round />
        </FacebookShareButton>,
        <WhatsappShareButton url={shareUrl} separator=":: " key="whatsapp">
            <WhatsappIcon size={iconSize} round />
        </WhatsappShareButton>,
        <LinkedinShareButton url={shareUrl} key="linkedin">
            <LinkedinIcon size={iconSize} round />
        </LinkedinShareButton>,
        <RedditShareButton url={shareUrl} key="reddit">
            <RedditIcon size={iconSize} round />
        </RedditShareButton>,
        <GabShareButton url={shareUrl} key="gab">
            <GabIcon size={iconSize} round />
        </GabShareButton>,
        <TwitterShareButton url={shareUrl} key="twitter">
            <XIcon size={iconSize} round />
        </TwitterShareButton>,
        <TelegramShareButton url={shareUrl} key="telegram">
            <TelegramIcon size={iconSize} round />
        </TelegramShareButton>,
        <EmailShareButton url={shareUrl} subject="Check this out!" body="Hello, I thought you might be interested in this: " key="email">
            <EmailIcon size={iconSize} round />
        </EmailShareButton>,
        <TumblrShareButton url={shareUrl} key="tumblr">
            <TumblrIcon size={iconSize} round />
        </TumblrShareButton>,
        <LivejournalShareButton url={shareUrl} key="livejournal">
            <LivejournalIcon size={iconSize} round />
        </LivejournalShareButton>,
        <MailruShareButton url={shareUrl} key="mailru">
            <MailruIcon size={iconSize} round />
        </MailruShareButton>,
        <ViberShareButton url={shareUrl} key="viber">
            <ViberIcon size={iconSize} round />
        </ViberShareButton>,
        <WorkplaceShareButton url={shareUrl} key="workplace">
            <WorkplaceIcon size={iconSize} round />
        </WorkplaceShareButton>,
        <LineShareButton url={shareUrl} key="line">
            <LineIcon size={iconSize} round />
        </LineShareButton>,
        <WeiboShareButton url={shareUrl} key="weibo">
            <WeiboIcon size={iconSize} round />
        </WeiboShareButton>,
        <PocketShareButton url={shareUrl} key="pocket">
            <PocketIcon size={iconSize} round />
        </PocketShareButton>,
        <InstapaperShareButton url={shareUrl} key="instapaper">
            <InstapaperIcon size={iconSize} round />
        </InstapaperShareButton>,
        <HatenaShareButton url={shareUrl} key="hatena">
            <HatenaIcon size={iconSize} round />
        </HatenaShareButton>,
    ];

    //console.log(shareUrl);
    return (
        <Box>
            <Button aria-describedby={id} variant='text' onClick={handleClick}><Share sx={{ mr: 1 }} /> Share</Button>
            <Popper id={id} open={open} anchorEl={anchorEl} sx={{ zIndex: popperZIndex }}>
                <Box display="flex" flexWrap="wrap" sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>
                    {buttons.map((buttonComp, index) => (
                        <Box m={1} key={index}>
                            {buttonComp}
                        </Box>
                    ))}
                </Box>
            </Popper>
        </Box>
    );
};
export default ShareButton;