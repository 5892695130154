import { Box, Button, Collapse, Typography } from '@mui/material';
import React from 'react';

interface FeedHelpCollapsibleProps {
    helpOpen: boolean;
    onToggleHelp: () => void;
}

const FeedHelpCollapsible: React.FC<FeedHelpCollapsibleProps> = ({ helpOpen, onToggleHelp }) => {

    return (
        <>
            {/* Add collapsible explanation */}
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                <Button
                    onClick={() => onToggleHelp()}
                    sx={{ textTransform: 'none' }}
                >
                    {helpOpen ? "▼" : "▶"} What is a feed?
                </Button>
            </Box>

            <Collapse in={helpOpen}>
                <Typography variant="body2" sx={{ mb: 2 }}>
                    A feed is your personal channel for sharing content with others. Think of it as:
                    <ul>
                        <li>A book where each post is a chapter</li>
                        <li>A newsletter where each post is an edition</li>
                        <li>A comic book where each post is a story</li>
                        <li>A personal blog where each post is an article</li>
                        <li>A podcast where each post is an episode</li>
                        <li>A tutorial series where each post is a lesson</li>
                    </ul>

                    People can subscribe to your feeds to stay updated with your content. When you publish new posts,
                    your subscribers will be notified and can easily find your latest content.
                    <br /><br />
                    You can create multiple feeds to organize different types of content. For example:
                    <ul>
                        <li>One feed for your technical tutorials</li>
                        <li>Another for your creative writing</li>
                        <li>A separate feed for your photography</li>
                        <li>A dedicated feed for your project updates</li>
                    </ul>

                    This way, your readers can subscribe specifically to the content that interests them most.
                    <br /><br />
                    <strong>Important Notes:</strong>
                    <ul>
                        <li>Feed names must be unique across <em>all</em> users - if someone else has already taken a name, you'll need to choose another</li>
                        <li>Feed names cannot be changed after creation, so choose carefully!</li>
                        <li>You can use lowercase letters, numbers, and underscores in your feed name</li>
                        <li>You can always edit the description and about section later</li>
                        <li>You can deactivate a feed at any time if you need to temporarily hide its content</li>
                    </ul>
                </Typography>
            </Collapse>
        </>
    );
};

export default FeedHelpCollapsible;