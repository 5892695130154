import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
    palette: {
        primary: {
            main: '#465e60',
        },
        secondary: {
            main: '#556cd6',
        },
        error: {
            main: red.A400,
        },
    },

    typography: {
        fontSize: 12,
        subtitle2: {
            fontWeight: 'bold',
            fontSize: '1rem',
            '@media (max-width:600px)': {
                fontSize: '0.75rem',
            },
        },
        h1: {
            fontSize: '2rem',
            '@media (max-width:600px)': {
                fontSize: '1.25rem',
            },
        },
        h2: {
            fontSize: '1.5rem',
            '@media (max-width:600px)': {
                fontSize: '1rem',
            },
        },
        body1: {
            fontSize: '1rem',
            '@media (max-width:600px)': {
                fontSize: '0.75rem',
            },
        },
        body2: {
            fontSize: '0.875rem',
            '@media (max-width:600px)': {
                fontSize: '0.625rem',
            },
        },
    },
});

export default theme;