import React from 'react';
import { Helmet } from 'react-helmet';

interface SEOStructuredProps {
    canonicalUrl: string;
    previewTitle?: string;
    previewDescription?: string;
    previewImage: string;
    structuredData: object;
}

const SEOStructured: React.FC<SEOStructuredProps> = ({ canonicalUrl, previewTitle, previewDescription, previewImage, structuredData }) => {
    return (
        <Helmet>
            <link rel="canonical" href={canonicalUrl} />
            <title>{previewTitle}</title>
            <meta property="og:title" content={previewTitle} />
            <meta property="og:description" content={previewDescription} />
            <meta property="og:image" content={previewImage} />
            <meta property="og:url" content={canonicalUrl} />
            <meta property="og:type" content="article" />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={previewTitle} />
            <meta name="twitter:description" content={previewDescription} />
            <meta name="twitter:image" content={previewImage} />
            <meta name="twitter:url" content={canonicalUrl} />

            <script type="application/ld+json">
                {JSON.stringify(structuredData)}
            </script>
        </Helmet>
    );
};

export default SEOStructured;