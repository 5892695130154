import { AccountBox, Create, Help, Logout, MoreVert, Notifications, Person, Settings } from '@mui/icons-material';
import { Avatar, Box, Button, Divider, IconButton, Link, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material';


import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInAction, signOutAction, useAuthInfoContext } from '../hocComponents/AuthProvider';
import { getGravatarUrl } from '../services/gravatar';
import GoogleSignInButton from './GoogleSignInButton';

export default function AuthenticatedUser() {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null);
    const navigate = useNavigate();

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const authInfo = useAuthInfoContext();


    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };
    const menuId = 'primary-search-account-menu';
    const commonMenu = [ // both mobile and desktop 
        (
            <MenuItem key="1" component={Link} href="/user-profile">
                <ListItemIcon>
                    <AccountBox />
                </ListItemIcon>
                <ListItemText primary="Profile" />
            </MenuItem>
        ),
        // (
        //     <MenuItem key="2" component={Link} href="/settings">
        //         <ListItemIcon>
        //             <Settings />
        //         </ListItemIcon>
        //         <ListItemText primary="Settings" />
        //     </MenuItem>
        // ),
        (
            <Divider key="3" />
        ),
        (
            <MenuItem key="4" component={Link} href="/manage-feeds">
                <ListItemIcon>
                    <Settings />
                </ListItemIcon>
                <ListItemText primary="Manage feeds" />
            </MenuItem>
        ),
        (
            <Divider key="5" />
        ),
        (
            <MenuItem key="6" component={Link} href="/view-md/terms_of_service.md">
                <ListItemIcon>
                    <Settings />
                </ListItemIcon>
                <ListItemText primary="Terms of service" />
            </MenuItem>
        ),
        (
            <MenuItem key="7" component={Link} href="/view-md/community_guidelines.md">
                <ListItemIcon>
                    <Settings />
                </ListItemIcon>
                <ListItemText primary="Community Guidelines" />
            </MenuItem>
        ),
        (
            <MenuItem key="8" component={Link} href="/view-md/privacy_policy.md">
                <ListItemIcon>
                    <Settings />
                </ListItemIcon>
                <ListItemText primary="Privacy policy" />
            </MenuItem>
        ),
        (
            <Divider key="9" />
        ),
        (
            <MenuItem key="100" onClick={signOutAction}>
                <ListItemIcon>
                    <Logout />
                </ListItemIcon>
                <ListItemText primary="Logout" />
            </MenuItem>
        )
    ];

    const renderMenu = (
        <Menu
            id={menuId}
            anchorEl={anchorEl}
            open={isMenuOpen}
            onClose={handleMenuClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
            {commonMenu}
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';

    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            {authInfo ? (
                <div>
                    <MenuItem>
                        <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                            {authInfo.providerInfo?.email}
                        </Typography>
                    </MenuItem>
                    <MenuItem component={Link} href="/Create" >
                        <ListItemIcon>
                            <Create />
                        </ListItemIcon>
                        <ListItemText primary="Create" />
                    </MenuItem>
                    <MenuItem>
                        <ListItemIcon>
                            <Notifications />
                        </ListItemIcon>
                        <ListItemText primary="Notifications" />
                        {/* <Badge color="info" badgeContent={3} sx={{ ml: 2 }} /> */}
                    </MenuItem>
                    <Divider />
                    {commonMenu}
                </div>) : (<>
                    <MenuItem onClick={signInAction}>
                        <ListItemIcon>
                            <Person />
                        </ListItemIcon>
                        <ListItemText primary="Sign In with google" />
                    </MenuItem>
                </>
            )}
            <Divider />
            <MenuItem onClick={
                () => {
                    navigate('/view-md/help.md');
                    handleMenuClose();
                }
            }>
                <ListItemIcon>
                    <Help />
                </ListItemIcon>
                <ListItemText primary="Help" />
            </MenuItem>
        </Menu>
    );

    return (
        <div>
            {authInfo ? (
                <div>
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}  >
                        <IconButton size="large" color="inherit" title='Create' aria-label="Create" LinkComponent={Link} href="/create">
                            <Create />
                        </IconButton>
                        <IconButton
                            size="large"
                            title="Show 3 new notifications"
                            aria-label="Show 3 new notifications"
                            color="inherit"
                        >
                            {/* <Badge badgeContent={3} color="info">
                                <Notifications />
                            </Badge> */}
                            <Notifications />
                        </IconButton>
                        <IconButton size="large" color="inherit" title='Help' aria-label="Help" LinkComponent={Link} href="/view-md/help.md">
                            <Help />
                        </IconButton>
                        <Button onClick={handleMenuOpen} sx={{ p: 0 }}>
                            <Avatar src={getGravatarUrl(authInfo.providerInfo?.email || "")} sx={{ width: 32, height: 32 }} />
                        </Button>

                    </Box>

                </div>
            ) : (
                <div>
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, ml: 3 }}  >
                        <IconButton size="large" color="inherit" title='Help' aria-label="Help" LinkComponent={Link} href="/view-md/help.md">
                            <Help />
                        </IconButton>
                        <GoogleSignInButton onClick={signInAction} />
                    </Box>
                </div>
            )}

            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                    size="large"
                    aria-label="show more"
                    aria-controls={mobileMenuId}
                    aria-haspopup="true"
                    onClick={handleMobileMenuOpen}
                    color="inherit"
                >
                    <MoreVert />
                </IconButton>
            </Box>
            {renderMobileMenu}
            {renderMenu}
        </div>
    );
}