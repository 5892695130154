import { AuthTokens, AuthUser, fetchAuthSession, getCurrentUser, signInWithRedirect, signOut } from "aws-amplify/auth";
import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import loadingSvg from '../assets/loading.svg';
import { AuthInfo } from "../services/common/Backend";
const AuthInfoContext = createContext<AuthInfo | undefined>(undefined);
export const useAuthInfoContext = () => useContext(AuthInfoContext);

export const signInAction = () => signInWithRedirect({ provider: "Google" });
export const signOutAction = () => signOut();


function authInfoFromCognitoUser(
    cognitoUser: AuthUser | null,
    tokens?: AuthTokens,
): AuthInfo | undefined {
    if (!cognitoUser) {
        return undefined;
    }
    return {
        providerInfo: {
            userId: cognitoUser.userId,
            userName: cognitoUser.username,
            // Needed for backend requests
            jwtAccessToken: tokens?.accessToken.toString() || 'not logged in',
            jwtIdToken: tokens?.idToken?.toString() || 'not logged in',
            email: tokens?.idToken?.payload.email?.toString(),
        }
    };
}

// Provider component that provides the JWT token
export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }: { children: ReactNode }) => {
    const [authInfoState, setAuthInfoState] = useState<AuthInfo | undefined>(undefined);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        checkUser();
        const intervalId = setInterval(checkUser, 5 * 60 * 1000); // Check every 5 minutes

        // Clean up interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    async function checkUser() {
        try {
            const cognitoUser: AuthUser = await getCurrentUser();
            const { tokens } = await fetchAuthSession();
            setAuthInfoState(authInfoFromCognitoUser(cognitoUser, tokens));
        } catch (error) {
            setAuthInfoState(undefined);
            console.log('user is not signed in. Error: ', error);
        }
        setLoading(false); // Done loading, even if there was an error
    }

    if (loading) {
        return <div style={{
            position: "relative",
            width: "100%",
            height: "100vh"
        }}>
            <img src={loadingSvg} alt="Loading..." style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)"
            }} />
        </div>;
    }
    return (
        <AuthInfoContext.Provider value={authInfoState}>
            {children}
        </AuthInfoContext.Provider>
    );
};