import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { CategoriesHeader } from '../components/CategoriesHeader';
import PostCardBox from '../components/PostCardBox';
import SEOStructured from '../components/SEOStructured';
import { Loader, LoaderResults, useNextPageLoader, withLoaders } from '../hocComponents/withLoaders';
import { withOptionalAuth } from '../hocComponents/withOptionalAuth';
import { AuthInfo } from '../services/common/Backend';
import { loadCategories } from '../services/loaders/categories';
import { Post, loadHomePosts, searchPosts } from '../services/loaders/postLoaders';
import { extractTitle } from '../services/markdownManipulation';

interface Props {
    authInfo: AuthInfo | undefined;
    loaderResults: LoaderResults;
}

const HomePage: React.FC<Props> = (props) => {
    const categoriesList = ['ALL', ...props.loaderResults.resultsByLoader.categories.items];
    const homePostsLoaderResult = props.loaderResults.resultsByLoader.homePosts;
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const itemsContainerRef = useRef<HTMLDivElement>(null);
    const { handleScroll } = useNextPageLoader(homePostsLoaderResult, scrollContainerRef, itemsContainerRef);

    const previewTitle = 'Home';
    const previewDescription = 'Home page';
    const previewImage = `${process.env.REACT_APP_BASE_URL}/logo192.png`;
    const canonicalUrl = `${process.env.REACT_APP_BASE_URL}`;
    const uniqueFeedPages = Array.from(new Set(props.loaderResults.resultsByLoader.homePosts.items.map((post: Post) => post.feed_name)));
    const relatedLinks = [
        ...uniqueFeedPages.map((feedName) => ({
            "@type": "CollectionPage",
            "name": feedName,
            "url": `${canonicalUrl}/feed/${feedName}`
        })),
        ...props.loaderResults.resultsByLoader.homePosts.items.map((post: Post) => ({
            "@type": "BlogPosting",
            "headline": extractTitle(post.content),
            "url": `${canonicalUrl}/post/${post.feed_name}/${post.id}`
        }))
    ];


    const structuredData = {
        "@context": "https://schema.org",
        "@type": "Website",
        "name": previewTitle,
        "description": previewDescription,
        "url": canonicalUrl,
        "publisher": {
            "@type": "Organization",
            "name": "Feed",
            "logo": {
                "@type": "ImageObject",
                "url": previewImage
            }
        },
        relatedLink: relatedLinks
    };

    return (
        <div
            ref={scrollContainerRef}
            style={{ overflowY: 'auto', height: '100%' }}
            onScroll={handleScroll}
        >
            <SEOStructured canonicalUrl={canonicalUrl} previewTitle={previewTitle} previewDescription={previewDescription} previewImage={previewImage} structuredData={structuredData} />
            <CategoriesHeader categories={categoriesList} />

            <div ref={itemsContainerRef}>
                <PostCardBox
                    posts={homePostsLoaderResult.items as Post[]}
                    authInfo={props.authInfo}
                />
            </div>
        </div>
    );
};

const HomePageWithLoaders: React.FC<Omit<Props, 'authInfo' | 'loaderResults'>> = (props) => {
    const { searchTerm } = useParams<{ searchTerm: string }>();

    const loadFeedDetailsP: Loader = (authInfo: AuthInfo | undefined, page: number) => (!searchTerm)
        ? loadHomePosts(authInfo, page)
        : searchPosts(authInfo, searchTerm, page)
        ;

    const HomePageComponent = withOptionalAuth(withLoaders(HomePage, {
        homePosts: loadFeedDetailsP,
        categories: loadCategories
    }));

    return <HomePageComponent {...props} />;
}

export default HomePageWithLoaders;