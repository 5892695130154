import { Box, Typography } from '@mui/material';
import GoogleSignInButton from '../components/GoogleSignInButton';
import { AuthInfo } from '../services/common/Backend';
import { signInAction, useAuthInfoContext } from './AuthProvider';

export function withAuth<P extends object>(Component: React.ComponentType<P & { authInfo: AuthInfo | undefined }>): React.FC<Omit<P, 'authInfo'>> {
    return (props: Omit<P, 'authInfo'>) => {
        const authInfo = useAuthInfoContext();

        if (!authInfo) {
            return (
                <Box display="flex" alignItems="center" width="100%" marginTop="10px" flexDirection="column">
                    <Box>
                        <Typography variant="h6">
                            You are not logged in.
                        </Typography>
                        <Typography variant="body1">
                            Please log in to continue.
                        </Typography>
                    </Box>
                    <Box sx={{ marginTop: 2 }}>
                        <GoogleSignInButton onClick={() => signInAction()} message="Login with Google" />
                    </Box>
                </Box>

            );
        }

        return <Component {...props as P} authInfo={authInfo} />;
    };
}