import { Box, Divider } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { AuthInfo } from "../../services/common/Backend";
import { PostComment, loadPostComments } from "../../services/loaders/comments";
import CommentCard from "./CommentCard";
import CommentListHeader from "./CommentListHeader";


interface CommentListProps {
    authInfo: AuthInfo | undefined,
    postId: string;
    parentCommentId: string | null;
    ident: boolean;
}

function CommentList({ postId, authInfo, parentCommentId, ident }: CommentListProps, ref: React.Ref<any>) {
    const [orderBy, setOrderBy] = useState<'newest' | 'oldest'>('newest'); // ['newest', 'oldest'
    const [postComments, setPostComments] = useState<PostComment[]>([]);

    function addComment(postComment: PostComment) {
        if (orderBy === 'newest') {
            setPostComments((prev) => [postComment, ...prev]);
        } else {
            setPostComments((prev) => [...prev, postComment]);
        }
    }
    useImperativeHandle(ref, () => ({
        addComment
    }));

    function handleEdit(postComment: PostComment) {
        setPostComments((prev) => prev.map((comment) => comment.id === postComment.id ? postComment : comment));
    }

    useEffect(() => {
        loadPostComments(authInfo, postId, parentCommentId, orderBy)
            .then((comments) => {
                setPostComments(comments);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [authInfo, postId, parentCommentId, orderBy]);

    ;

    return (
        <Box>
            {!ident &&
                <CommentListHeader
                    authInfo={authInfo} postId={postId} numComments={postComments.length}
                    orderBy={orderBy}
                    onChangeOrderBy={setOrderBy}
                />}
            <Box>
                {
                    postComments.length === 0
                        ? "No comments yet"
                        : postComments.map((postComment, index) => <div key={postComment.id}>
                            <CommentCard authInfo={authInfo} postId={postId} comment={postComment} ident={ident}
                                onEdit={handleEdit} />
                        </div>
                        )
                }
                <Divider variant="fullWidth" style={{ margin: "10px 0" }} />
            </Box>
        </Box>
    )
}



export default forwardRef(CommentList);