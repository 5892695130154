import { Box } from "@mui/material";
import { useRef } from "react";
import { AuthInfo } from "../../services/common/Backend";
import { PostComment } from "../../services/loaders/comments";
import CommentEditor from "./CommentEditor";
import CommentList from "./CommentList";


interface CommentSectionProps {
    authInfo: AuthInfo | undefined,
    postId: string;
}


function CommentSection({ postId, authInfo }: CommentSectionProps) {
    const commentListRef = useRef<any>(null);

    function handleSaveComment(postComment: PostComment): void {
        commentListRef.current?.addComment(postComment);
    }

    return (
        <Box flex={1} flexDirection="column">
            <CommentEditor authInfo={authInfo} postId={postId} parentCommentId={null} commentId={null} hideCancel={true}
                onSave={handleSaveComment} />
            <CommentList ref={commentListRef} authInfo={authInfo} postId={postId} parentCommentId={null} ident={false} />
        </Box>
    )
}

export default CommentSection;