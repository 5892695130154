import { CloseFullscreen, Fullscreen, FullscreenOutlined } from '@mui/icons-material';
import { Avatar, Box, Button, ButtonBase, Dialog, Grid, Paper, Typography } from '@mui/material';
import { MdPreview } from 'md-editor-rt';
import 'md-editor-rt/lib/style.css';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import LikeDislikeButtons from '../components/LikeDislikeButtons';
import PostCardBox from '../components/PostCardBox';
import SEOStructured from '../components/SEOStructured';
import ShareButton from '../components/ShareButton';
import SubscribeButton from '../components/SubscribeButton';
import CommentSection from '../components/comments/CommentSection';
import { zIndex } from '../config/constants';
import { Loader, LoaderResults, useNextPageLoader, withLoaders } from '../hocComponents/withLoaders';
import { withOptionalAuth } from '../hocComponents/withOptionalAuth';
import { AuthInfo } from '../services/common/Backend';
import { getGravatarUrl } from '../services/gravatar';
import { Post, loadPostDetails, loadRelatedPosts } from '../services/loaders/postLoaders';
import { isSubscribed, numSubscribers } from '../services/loaders/subscriptions';
import { imageUrlForFeed } from '../services/loaders/userFeeds';
import { extractPreview, extractTitle } from '../services/markdownManipulation';
import { isMobileViewport } from '../services/mobileUtils';
import "../styles/mdEditorCustomStyle.css";

type Props = {
    authInfo: AuthInfo | undefined,
    loaderResults: LoaderResults;
};

const ViewPostPage: React.FC<Props> = ({ authInfo, loaderResults, }) => {
    const { postId } = useParams<{ postId: string }>();
    const [isMobileMode, setIsMobileMode] = useState<boolean>(false);

    const postDetails = loaderResults.resultsByLoader.postDetails?.items[0];
    const relatedPostsLoaderResults = loaderResults.resultsByLoader.relatedPosts;

    const scrollContainerRef = useRef<HTMLDivElement | null>(null);
    const itemsContainerRef = useRef<HTMLDivElement | null>(null);
    const { handleScroll } = useNextPageLoader(relatedPostsLoaderResults, scrollContainerRef, itemsContainerRef);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileMode(isMobileViewport());
        };

        handleResize(); // Set initial value
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [isFullScreen, setIsFullScreen] = useState(false);
    const toggleFullScreen = () => {
        console.log('Toggling full screen');
        setIsFullScreen((_prev) => true);
    }

    if (!postId) return (<div>Invalid post id</div>);


    const postTitle = extractTitle(postDetails.post.content);
    const postContentsSummary = extractPreview(postDetails.post.content || '', 500)
    const postImage = getGravatarUrl(postDetails.post.user_email || "");
    const canonicalUrl = `${process.env.REACT_APP_BASE_URL}/post/${postDetails.post.feed_name}/${postDetails.post.id}`;


    const previewComponent = <MdPreview
        modelValue={postDetails.post.content}
        language="en-US"
        showCodeRowNumber={true}
    />;

    const shareButton = <ShareButton popperZIndex={zIndex.viewPostShareButton} />;
    const subscribeButton = <SubscribeButton authInfo={authInfo} feedName={postDetails.post.feed_name} />;
    const likeDislikeButtons = <LikeDislikeButtons authInfo={authInfo} postId={postId} />;

    const relatedLinks = [
        ...relatedPostsLoaderResults.items.map(post => ({
            "@type": "Article",
            "headline": postTitle,
            "url": `${process.env.REACT_APP_BASE_URL}/post/${post.feed_name}/${post.id}`,
        })),
        ...Array.from(new Set(relatedPostsLoaderResults.items.map(post => post.feed_name))).map(feedName => ({
            "@type": "ProfilePage",
            "name": feedName,
            "url": `${process.env.REACT_APP_BASE_URL}/feed/${feedName}`,
        }))
    ];

    const structuredData = {
        "@context": "https://schema.org",
        "@type": "Article",
        "headline": postTitle,
        "author": {
            "@type": "Person",
            "name": postDetails.post.user_email,
            "url": `${process.env.REACT_APP_BASE_URL}/feed/${postDetails.post.feed_name}`
        },
        "datePublished": postDetails.post.created_at,
        "image": postImage,
        "publisher": {
            "@type": "Organization",
            "name": "YouRead.app",
            "logo": {
                "@type": "ImageObject",
                "url": `${process.env.REACT_APP_BASE_URL}/logo192.png`
            }
        },
        "articleBody": postContentsSummary,
        "relatedLink": relatedLinks
    };

    return (
        <Box
            id="main_box_view_post" display="flex" flexWrap="wrap" justifyContent="center" flexDirection="row" padding={0}
            ref={scrollContainerRef}
            sx={{ overflowY: 'auto', height: '100%' }}
            onScroll={handleScroll}
        >
            <SEOStructured canonicalUrl={canonicalUrl} previewTitle={postTitle} previewDescription={postContentsSummary} previewImage={postImage} structuredData={structuredData} />
            <Dialog
                fullScreen
                open={isFullScreen}
                onClose={() => setIsFullScreen(false)}
            >
                <Box display="flex" width="100%" justifyContent="flex-end">
                    <Button variant='text' onClick={() => setIsFullScreen(false)} ><CloseFullscreen /></Button>
                </Box>
                {previewComponent}
            </Dialog>
            <Box id="article-and-comment-box" display="flex" flexWrap="wrap" alignContent="flex-start" padding={1} flexGrow={1} flexBasis="66vw" >
                <Box width="100%" maxHeight={isMobileMode ? "auto" : "60vh"} overflow="auto" >
                    {previewComponent}
                </Box>

                {!isMobileMode &&
                    <Box id="article-toolbar2-box-desktop" width="100%" padding={0} display="flex" flexWrap="wrap">
                        <Box id="article-toolbar-box-desktop" width="100%" >
                            <Grid container spacing={0} margin={0} >
                                <Grid item width="100%">
                                    <hr />
                                    <Typography gutterBottom variant="h6" component="div" margin={0}>
                                        {postTitle}
                                    </Typography>
                                </Grid>

                                <Grid item xs />
                                {isMobileMode && (
                                    <Grid item>
                                        <Button variant='text' onClick={toggleFullScreen}><Fullscreen /></Button>
                                    </Grid>
                                )}
                            </Grid>
                        </Box>
                        <Grid container spacing={2}>
                            <Grid item>
                                <ButtonBase sx={{ width: 40, height: 40 }}>
                                    <Avatar src={imageUrlForFeed(postDetails.post.feed_name)} sx={{ width: "100%", height: "100%", mr: 2, ml: 2 }} />
                                </ButtonBase>
                            </Grid>
                            <Grid item  >
                                <Grid item xs container direction="column" >
                                    <Grid item xs>
                                        <Typography gutterBottom variant="subtitle2" component="div" margin={0}>
                                            <Link to={`/feed/${postDetails.post.feed_name}`}>@{postDetails.post.feed_name}</Link>
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary" margin={0}>
                                            {postDetails.numSubscribers} subscribers
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item >
                                {subscribeButton}
                            </Grid>
                            <Grid item xs sm container >
                                <Grid item xs>
                                </Grid>
                                <Grid item>
                                    {likeDislikeButtons}
                                </Grid>
                                <Grid item xs />
                                <Grid item>
                                    {shareButton}
                                </Grid>
                                <Grid item xs />
                                <Grid item>
                                    <Button variant='text' onClick={toggleFullScreen}><Fullscreen sx={{ mr: 1 }} />Fullscreen</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                }
                <Box id="comments-box" width="100%" mt={2}>
                    <hr />
                    <CommentSection postId={postId} authInfo={authInfo}></CommentSection>
                </Box>
            </Box>
            <Box
                id="related-box-container" flexGrow={1} flexBasis="25vw" mb="250px"
                ref={itemsContainerRef}
            >
                <Box>
                    <h3>Related</h3>
                </Box>
                <PostCardBox posts={relatedPostsLoaderResults.items} authInfo={authInfo} align="center" />
            </Box>
            {isMobileMode &&
                <Box id="article-toolbar2-box-mobile" width="100%" padding={0} display="flex" flexWrap="wrap">
                    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: zIndex.viewPostBottomPaper, p: 0.5 }} elevation={3} >
                        <Box id="article-toolbar-box" width="100%" p={0.5}>
                            <Grid container spacing={0} margin={0}>
                                <Grid item xs>
                                    <Typography gutterBottom variant="h6" component="div" margin={0}>
                                        {extractTitle(postDetails.post.content)}
                                    </Typography>
                                </Grid>

                                <Grid item xs />
                                <Grid item  >
                                    <Button variant='outlined' onClick={toggleFullScreen} ><FullscreenOutlined /></Button>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box display="inline-flex" flexWrap="wrap" justifyContent='space-between' p={0.5}>
                            <ButtonBase sx={{ width: 32, height: 32, mr: 2 }}>
                                <Avatar src={postImage} sx={{ width: "100%", height: "100%", }} />
                            </ButtonBase>
                            <Box>
                                <Typography gutterBottom variant="subtitle2" component="div" margin={0} >
                                    <Link to={`/feed/${postDetails.post.feed_name}`}>{`@${postDetails.post.feed_name}`}</Link>
                                </Typography>
                                <Typography variant="body2" color="text.secondary" margin={0}>
                                    {postDetails.numSubscribers} subscribers
                                </Typography>
                            </Box>
                            <Box flexGrow={1} />
                            {subscribeButton}
                            <Box width="100%" />
                            {likeDislikeButtons}
                            {shareButton}
                        </Box>
                    </Paper>
                </Box>
            }
        </Box >
    );
};

const ViewPostPageWithLoaders: React.FC<Omit<Props, 'authInfo' | 'reload' | 'loaderResults'>> = (props) => {
    const { postId } = useParams<{ postId: string }>();
    if (!postId) {
        return <div>Post id not provided</div>;
    }
    const loadPostDetailsP: Loader = async (authInfo: AuthInfo | undefined) => {
        let postDetails: Post = await loadPostDetails(authInfo, postId);
        let feed_name = postDetails.feed_name;

        return [{
            post: postDetails,
            isSubscribed: await isSubscribed(authInfo, feed_name),
            numSubscribers: await numSubscribers(authInfo, feed_name),
        }]
    };
    const loadRelatedPostsP: Loader = (authInfo: AuthInfo | undefined, page: number) => loadRelatedPosts(authInfo, postId, page);

    const FeedPageComponent = withOptionalAuth(withLoaders(ViewPostPage, {
        postDetails: loadPostDetailsP,
        relatedPosts: loadRelatedPostsP,
    }));

    return <FeedPageComponent {...props} />;
}

export default ViewPostPageWithLoaders;