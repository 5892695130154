import { AuthInfo, call_backend, call_backend_post } from "../common/Backend";

export function formatNumComments(numComments: number): string {
    return numComments < 1000 ? numComments.toString() : '1k+';
}

export interface PostComment {
    post_id: number;
    author_email: string;
    body: string;
    id: string | null;
    parent: string | null;
    is_liked: boolean;
    likes_count: number;
    replies_count: number;
    created_at: Date;
}

export async function loadPostComments(
    authInfo: AuthInfo | undefined,
    postId: string, parent: string | null,
    sort_by: 'newest' | 'oldest',
    page: number = 1,
): Promise<PostComment[]> {
    const url = 'public/fetch_comments/' + postId
        + (parent ? '?parent=' + parent : '')
        + (sort_by ? (parent ? '&' : '?') + 'sort_by=' + sort_by : '')
        + (page ? (parent || sort_by ? '&' : '?') + 'page=' + page : '')
        ;
    const response = await call_backend(authInfo, url);
    const responseData = await response.json();
    return responseData.map((comment: any) => {
        return {
            ...comment,
            created_at: new Date(comment.created_at),
        } as PostComment;
    });
}

export async function savePostComment(authInfo: AuthInfo, comment: PostComment): Promise<PostComment> {
    const response = await call_backend_post(authInfo, 'secure/save_comment', comment);
    const savedComment = await response.json();
    return {
        ...savedComment,
        created_at: new Date(comment.created_at),
    } as PostComment;
}

export async function likePostComment(authInfo: AuthInfo, postId: string, commentId: string): Promise<void> {
    await call_backend_post(authInfo, 'secure/like_post_comment/' + postId + "/" + commentId, {}, true);
}

export async function unlikePostComment(authInfo: AuthInfo, postId: string, commentId: string): Promise<void> {
    await call_backend_post(authInfo, 'secure/unlike_post_comment/' + postId + "/" + commentId, {}, true);
}

export async function hasUserLikedPost(authInfo: AuthInfo, postId: string, commentId: string): Promise<boolean> {
    const response = await call_backend(authInfo, 'secure/has_post_comment_like/' + postId + "/" + commentId, true);
    return response.status === 200;
}