import { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { Amplify } from "aws-amplify";
import { BrowserRouter } from "react-router-dom";
import awsConfig from '../aws-exports';
import { router } from '../router/router';
import { isMobileViewport } from '../services/mobileUtils';
import { AppSideBar, DrawerHeader } from './AppSideBar';
import { AppBarTop } from './AppTopBar';
Amplify.configure(awsConfig);

function App() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const handleToggleSidebar = () => setSidebarOpen(!sidebarOpen);
  const [isMobileMode, setIsMobileMode] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileMode(isMobileViewport());
    };

    handleResize(); // Set initial value
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <BrowserRouter>
      <Box
        display={'flex'} alignContent={'flex-start'}
        alignItems={'flex-start'} textAlign={'left'} alignSelf={'left'} height="90vh"
      >
        <AppBarTop handleToggleSidebar={handleToggleSidebar} />

        <AppSideBar open={sidebarOpen} isMobileMode={isMobileMode} />
        <Box component="main" style={{ width: "100%", height: "100%" }}>
          <DrawerHeader />
          {router}
        </Box>
      </Box>
    </BrowserRouter>
  );
}

export default App;
