import { Avatar, Box, Tab, Tabs, Typography } from "@mui/material";
import { MdPreview } from 'md-editor-rt';
import 'md-editor-rt/lib/style.css';
import React, { useRef } from "react";
import { useParams } from "react-router-dom";
import DateOrTimeAgo from "../components/DateOrTimeAgo";
import PostCardBox from "../components/PostCardBox";
import SEOStructured from "../components/SEOStructured";
import SubscribeButton from "../components/SubscribeButton";
import { useAuthInfoContext } from "../hocComponents/AuthProvider";
import { Loader, LoaderResults, useNextPageLoader, withLoaders } from "../hocComponents/withLoaders";
import { withOptionalAuth } from "../hocComponents/withOptionalAuth";
import { AuthInfo } from "../services/common/Backend";
import { FeedDetails, loadFeedDetails } from "../services/loaders/feedDetails";
import { loadFeedDrafts, loadFeedPosts, Post } from "../services/loaders/postLoaders";
import { numSubscribers } from "../services/loaders/subscriptions";
import { imageUrlForFeed } from "../services/loaders/userFeeds";
import "../styles/mdEditorCustomStyle.css";

type Props = {
  authInfo: AuthInfo | undefined,
  loaderResults: LoaderResults;
};

const FeedPage: React.FC<Props> = (props) => {
  const authInfo = useAuthInfoContext();
  const feedDetails = props.loaderResults.resultsByLoader.feedDetails.items[0] as FeedDetails;
  const [activeTab, setActiveTab] = React.useState("your-posts");
  const avatarWidth = 150;
  const avatarBoxWidth = avatarWidth + 50;


  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const postsLoaderResults = props.loaderResults.resultsByLoader.posts;
  const postItemsContainerRef = useRef<HTMLDivElement>(null);
  const { handleScroll: handlePostsScroll } = useNextPageLoader(postsLoaderResults, scrollContainerRef, postItemsContainerRef);

  const draftsLoaderResults = props.loaderResults.resultsByLoader.drafts;
  const draftsItemsContainerRef = useRef<HTMLDivElement>(null);
  const { handleScroll: handleDraftsScroll } = useNextPageLoader(draftsLoaderResults, scrollContainerRef, draftsItemsContainerRef);

  const handleTabChange = (event: React.SyntheticEvent, newTab: string) => {
    setActiveTab(newTab);
  };


  const feedName = feedDetails?.name;
  const feedDescription = feedDetails?.description;
  const feedImage = imageUrlForFeed(feedDetails?.name);
  const canonicalUrl = `${process.env.REACT_APP_BASE_URL}/feed/${feedDetails?.name}`;
  const relatedLinks = postsLoaderResults.items.map((post) => ({
    "@type": "BlogPosting",
    "headline": post.title,
    "url": `${canonicalUrl}/post/${post.feed_name}/${post.id}`
  }));
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "ProfilePage",
    "name": feedName,
    "description": feedDescription,
    "url": canonicalUrl,
    "publisher": {
      "@type": "Organization",
      "name": "Feed",
      "logo": {
        "@type": "ImageObject",
        "url": feedImage
      }
    },
    "relatedLink": relatedLinks
  };


  return (
    <Box id="feed-page-box" width="100%"
      sx={{
        display: "flex", flexDirection: "column", alignItems: "start",
        height: '85vh', overflowY: 'auto'
      }} // Set a fixed height
      ref={scrollContainerRef}
      onScroll={activeTab === "your-posts" ? handlePostsScroll : handleDraftsScroll} // Attach the scroll handler
    >
      <SEOStructured canonicalUrl={canonicalUrl} previewTitle={feedName} previewDescription={feedDescription} previewImage={feedImage} structuredData={structuredData} />
      <Box
        display="flex" flexWrap="wrap" width="100%"
      >
        <Box sx={{
          display: "flex", flexWrap: "nowrap", flexDirection: "column", m: 1,
          maxWidth: avatarBoxWidth

        }}>
          <Avatar
            src={feedImage}
            alt={"@" + feedDetails.name + " profile"}
            sx={{ width: avatarWidth, height: avatarWidth, mb: 2, mr: 2 }}
          >

          </Avatar>
          <Typography variant="h6" component="h6" sx={{ mb: 1, width: avatarBoxWidth }}>
            {feedDescription}
          </Typography>
          <Box >
            <Typography variant="body2" sx={{ mr: 2 }}>
              Created <DateOrTimeAgo date={feedDetails?.created_at} />
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <Typography variant="body2" sx={{ mr: 2 }}>
              @{feedDetails?.name}
            </Typography>
            <Typography variant="body2" sx={{ mr: 2 }}>
              {props.loaderResults.resultsByLoader.numSubscribers.items[0]} subscribers
            </Typography>
          </Box>
          <SubscribeButton authInfo={authInfo} feedName={feedDetails?.name} />
        </Box>

        <Box flexGrow={1}>
          <MdPreview
            modelValue={feedDetails?.about || ""}
            language="en-US"
          />
        </Box>
      </Box>

      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        sx={{ width: "90%", borderBottom: 1, borderColor: "divider" }}
      >
        <Tab label="Posts" value="your-posts" />
        {authInfo &&
          <Tab label="Drafts" value="your-drafts" />
        }
      </Tabs>

      {activeTab === "your-posts" && (
        <div ref={postItemsContainerRef}  >
          <PostCardBox
            posts={postsLoaderResults.items}
            authInfo={authInfo}
          />
        </div>
      )}

      {activeTab === "your-drafts" && (
        <div ref={draftsItemsContainerRef} >
          <PostCardBox
            posts={draftsLoaderResults.items}
            authInfo={authInfo}
          />
        </div>
      )}
      <Box sx={{ height: 150 }}></Box>
    </Box>

  );

};

const FeedPageWithLoaders: React.FC<Omit<Props, 'authInfo' | 'loaderResults'>> = (props) => {
  const { feedName } = useParams<{ feedName: string }>();
  if (!feedName) {
    return <div>Feed name not provided</div>;
  }
  const loadFeedPostsP: Loader = (authInfo: AuthInfo | undefined, page: number) => loadFeedPosts(authInfo, feedName, page);
  const loadFeedDraftsP: Loader = (authInfo: AuthInfo | undefined, page: number) => (authInfo) ? loadFeedDrafts(authInfo, feedName, page) : Promise.resolve<Post[]>([]);

  const FeedPageComponent = withOptionalAuth(withLoaders(FeedPage, {
    feedDetails: async (authInfo: AuthInfo | undefined) => [await loadFeedDetails(authInfo, feedName)],
    posts: loadFeedPostsP,
    drafts: loadFeedDraftsP,
    numSubscribers: async (authInfo: AuthInfo | undefined) => [await numSubscribers(authInfo, feedName)],
  }));

  return <FeedPageComponent {...props} />;
}

export default FeedPageWithLoaders; 